@import '~bootstrap/scss/bootstrap';
@import '~sweetalert2/dist/sweetalert2.css';

@import url('https://pro.fontawesome.com/releases/v5.15.3/css/all.css');
@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@400;700&family=Roboto:wght@400;700&display=swap');

$red-color: #ef4444;
$blue-color: #3b82f6;
$yellow-color: #ffc107;
$dark-gray-color: #1f2937;
$light-gray-color: #9ca3af;

// COMMON
body {
	font-family: 'Roboto', 'Kanit', sans-serif;
	min-height: 100vh;
}
.form-floating:focus-within {
	z-index: 2;
}
.link {
	color: $yellow-color;
	cursor: pointer;
	text-decoration: none;

	&:hover {
		color: darken($yellow-color, 10%);
	}
}
.input-helper {
	margin-top: 0.25rem;
	font-size: 0.75rem;
}
.form-control:focus {
	border-color: $yellow-color;
	box-shadow: 0 0 0 0.25rem rgba(255, 193, 7, 0.25);
}
.swal2-confirm {
	color: #000 !important;
	background-color: $yellow-color !important;

	&:focus {
		box-shadow: 0 0 0 0.25rem rgba(255, 193, 7, 0.25) !important;
	}
}
.page-content {
	max-width: 60rem;
	margin: auto;

	.page-header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin: 2rem 0;

		.page-title {
			i {
				color: $yellow-color;
			}
			h3 {
				font-weight: bold;
				font-size: 2rem;
				margin-bottom: 0;
			}
		}
		.page-actions {
		}
	}
	.card-header {
		font-size: 1rem;
		text-align: center;
	}
	.page-section {
		margin-bottom: 1.75rem;

		&.with-border {
			padding: 1.5rem;
			border: 1px solid #eee;
			border-radius: 0.25rem;
		}
		.page-section-header {
			h3 {
				font-weight: bold;
				font-size: 1.4rem;
			}
			h6 {
				font-size: 0.8rem;
			}

			&.with-actions {
				display: flex;
				justify-content: space-between;
				align-items: center;
			}
		}
		.page-section-content {
			margin-top: 1rem;

			th {
				font-weight: normal;
				border-color: #eee;
				color: darken($light-gray-color, 5%);
				font-size: 0.8rem;
			}
			td {
				border-color: #eee;
				vertical-align: middle;
			}
		}
	}
}
a {
	text-decoration: none !important;
}

@media screen and (max-width: 540px) {
	.page-header {
		display: block !important;

		.page-actions:not(.desktop) {
			margin-top: 2rem;
		}
		.page-actions.desktop {
			display: none;
		}
	}
}

// NAVBAR
.navbar-brand i {
	color: $yellow-color;
}
.nav-link {
	cursor: pointer;
}

// LOADING PAGE
.loading-page {
	display: flex;
	width: 100%;
	min-height: 100vh;
	justify-content: center;
	align-items: center;

	i {
		font-size: 5rem;
		color: $yellow-color;
	}
}

// AUTH
.auth-container {
	width: 100%;
	min-height: 100vh;
	max-width: 20rem;
	margin: auto;
	padding-top: 10rem;
	text-align: center;

	.auth-heading {
		margin-bottom: 2rem;

		i {
			color: $yellow-color;
		}
		h5 {
			margin-top: 1rem;
			font-weight: bold;
		}
	}
	form {
		input {
			margin-top: 1rem;
		}
		button {
			width: 100%;
			margin-top: 1rem;
		}
		.auth-suggestion {
			color: $light-gray-color;
			margin-top: 1rem;
		}
	}
}

// REFERRAL
.referral-summary {
	text-align: center;

	h3 {
		font-size: 2rem;
	}
	small {
		font-size: 1rem;
	}
}
.referral-create-link-dialog {
	.referral-rates {
		background-color: $yellow-color;
		border-radius: 0.25rem;
		padding: 1rem 1.5rem;

		.referral-rates-item {
			text-align: center;

			h3 {
				font-size: 0.8rem;
				margin-bottom: 0.5rem;
			}
			h6 {
				font-size: 1.4rem;
				margin-bottom: 0;
				font-weight: bold;
			}
		}
	}
	.referral-rates-selector {
		margin-top: 1rem;

		.referral-selector-text {
			font-size: 0.8rem;
			color: $light-gray-color;
		}
		.referral-rates-options {
			display: flex;
			margin-top: 0.5rem;
			gap: 1rem;

			.referral-rates-options-item {
				flex: 1;
				border: 1px solid #eee;
				border-radius: 0.25rem;
				text-align: center;
				font-size: 1.2rem;
				padding: 0.5rem 0.25rem;
				cursor: pointer;
				transition: 0.2s ease all;

				&:not(.disabled) {
					&:hover {
						border-color: $yellow-color;
					}
					&.active {
						border-color: $yellow-color;
						background-color: lighten($yellow-color, 45%);
					}
				}
				&.disabled {
					cursor: not-allowed;
					background-color: #eee;
				}
			}
		}
	}
	button {
		width: 100%;
	}
}

// REFERRAL WITHDRAW
.withdraw-desc {
	h5 {
		font-size: 0.8rem;
		margin-bottom: 0.2rem;
	}
	h6 {
		font-size: 0.6rem;
		margin-top: 0.5rem;
		margin-bottom: 0;
	}
}

// DIALOG
.dialog {
	display: flex;
	position: fixed;
	justify-content: center;
	align-items: center;
	z-index: 999;
	inset: 0;
	background-color: rgba(0, 0, 0, 0.5);

	.dialog-wrapper {
		width: 25rem;
		background-color: #fff;
		padding: 2rem;
		border-radius: 0.25rem;

		.dialog-header {
			h3 {
				font-size: 1.6rem;
				font-weight: bold;
			}
			h6 {
				font-size: 0.8rem;
			}
		}
		.dialog-content {
			margin-top: 1.5rem;
		}
	}
}

// TOPUP
.bank-topup-dialog {
	.bank-topup-qr-code {
		text-align: center;

		img {
			width: 150px;
		}
	}
	.bank-topup-suggestion {
		text-align: center;
		font-size: 0.8rem;
		margin-top: 1rem;
		color: $yellow-color;

		span {
			text-decoration: underline;
			font-weight: bold;
		}
	}
}
.topup-tx-tr {
	.topup-tx-details {
		h5 {
			font-size: 1rem;
			margin-bottom: 0.25rem;
		}
		h6 {
			font-size: 0.8rem;
			margin-bottom: 0;
		}
	}
}

// DASHBOARD
.task-list-tr {
	.task-list-details {
		max-width: 10rem;

		h5 {
			font-size: 1rem;
			margin-bottom: 0.25rem;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
		h6 {
			font-size: 0.8rem;
			margin-bottom: 0;
		}
	}
}

// SUBSCRIPTION
.add-subscription-dialog {
	button {
		width: 100%;
	}
	.subscription-duration-selector {
		margin-top: 1rem;

		.subscription-duration-text {
			font-size: 0.8rem;
			color: $light-gray-color;
		}
		.subscription-duration-options {
			display: flex;
			margin-top: 0.5rem;
			gap: 1rem;

			.subscription-duration-options-item {
				flex: 1;
				border: 1px solid #eee;
				border-radius: 0.25rem;
				text-align: center;
				font-size: 1rem;
				padding: 0.5rem 0.25rem;
				cursor: pointer;
				transition: 0.2s ease all;

				&:hover {
					border-color: $yellow-color;
				}
				&.active {
					border-color: $yellow-color;
					background-color: lighten($yellow-color, 45%);
				}
			}
		}
	}
}
.subscription-tr {
	.subscription-td-details {
		h5 {
			font-size: 1rem;
			font-weight: bold;
			margin-bottom: 0.5rem;
		}
		h6 {
			font-size: 0.75rem;
			margin-bottom: 0;

			b {
				color: $yellow-color;
			}
		}
	}
}
.subscription-remark {
	font-size: 0.8rem;
}
